<template>
  <div>
    <token-search
      :openSearch="showTokensList"
      :selectedToken="selectedToken"
      @tokenSelected="setupToken($event), (showTokensList = false)"
      @close="showTokensList = false"
    />

    <new-stake
      v-if="showStakeWindow"
      :tokenAddress="tokenAddress"
      :tokenDecimals="tokenDecimals"
      :showStakeWindow="showStakeWindow"
      :stakeAmount="stakeAmount"
      :tokenContract="tokenContract"
      :tokenBalance="tokenBalance"
      :pool="poolData"
      @poolLimitExceed="stakeAmount = $event"
      @poolLimitReached="showPoolLimitReached=true, stakeAmount=$event"
      @waitStake="showPendingStake = true"
      @waitApprove="showPendingApprove = true"
      @showError="showError = true"
      @close="
        (showPendingStake = false),
          (showPendingApprove = false),
          (showSuccessApprove = false),
          (showSuccessStake = false)
      "
      @approveSuccess="showSuccessApprove = true"
      @stakeSuccess="showSuccessStake = true, redirectToStake()"
      @closeStakeWindow="showStakeWindow = false"
    />

    <transaction-status
      :stakeAmount="stakeAmount"
      :tokenName="selectedToken"
      :showPendingStake="showPendingStake"
      :showPendingApprove="showPendingApprove"
      :showSuccessStake="showSuccessStake"
      :showSuccessApprove="showSuccessApprove"
      :showError="showError"
      :showPoolLimitReached="showPoolLimitReached"
      @close="
        (showPendingStake = false),
          (showSuccessStake = false),
          (showPendingApprove = false),
          (showSuccessApprove = false),
          (showError = false),
          (showPoolLimitReached = false)
      "
    />
    <loader v-if="showLoader" />

    <main class="cabinet">
      <div class="container">
        <div class="cabinet-header">
          <div class="h1">{{lang.get("CABINET_WELCOME_TITLE")}}</div>
          <cabinet-navigation />
        </div>
        <div class="wrapper-content">
          <div class="card card_input sidebar">
            <div class="block_sidebar">
              <div class="block">
                <div class="block_title">
                  <div class="count">1</div>
                  <div class="title">{{lang.get("CHOOSE_YOUR_TOKEN")}}</div>
                </div>
                <div class="input" @click="showTokensList = true">
                  <div v-if="!selectedToken" class="inline_input">
                    <button
                      class="button-select_token"
                      data-cy="button-select_token"
                    >
                      {{lang.get("CHOOSE_YOUR_TOKEN")}}
                    </button>
                    <i class="i-arrow"></i>
                  </div>
                  <div v-else class="inline_input">
                    <div class="token_img">
                      <div :class="selectedToken"></div>
                    </div>
                    <button
                      class="button-select_token"
                      data-cy="button-select_token-2"
                    >
                      {{ tokenTicker(selectedToken) }}
                    </button>
                    <i class="i-arrow"></i>
                  </div>

                  <!-- <div class="token_img"><div class="token-a"></div></div>
                    <input v-if="!selectedToken" placeholder="Choose your token">
                    <input v-else :value="selectedToken">
                  <i class="i-arrow"></i> -->
                </div>
              </div>
              <div class="block">
                <div class="block_title">
                  <div class="count">2</div>
                  <div class="title">{{lang.get("ENTER_AMOUNT")}}</div>
                </div>
                <div class="input" :class="{active: showInputError}" id="number">
                  <input
                    @input="checkBalance(), getTokensRates()"
                    type="number"
                    min="0"
                    placeholder="Enter the amount"
                    v-model="stakeAmount"
                    
                  />
                  <button
                    @click="setMaxValue()"
                    class="button button_max"
                    data-cy="button_max"
                  >
                    {{lang.get("MAX")}}
                  </button>
                </div>
                <div class="addinfo">
                  <i class="i-balance"></i>
                  <span>{{lang.get("WALLET_BALANCE")}} {{ tokenBalance }}</span>
                </div>
                <div v-if="lowBalance" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("BALANCE_INSUF_WARNING")}}</div>
                </div>
                <div v-if="incorrectAmount" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("ZERO_AMOUNT")}}</div>
                </div>
                <div v-if="showPoolLimitExceedError" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("POOL_LIMIT_EXCEED")}} {{maxStake}}</div>
                </div>
                <div v-if="minStakeAmount" class="inform">
                  <i class="i-attention"></i>
                  <div>{{lang.get("MIN_STAKE_AMOUNT")}} {{poolData.userMinStake}}</div>
                </div>
              </div>
            </div>
            <button
              data-cy="button_submit-input-pool"
              @click="findPools(), getTokensRates()"
              class="button button_submit-input"
            >
              {{lang.get("SHOW_POOLS")}}
            </button>
          </div>

          <!-- поки користувач не підключив гаманець -->
          <div v-if="!currentAddress || showAnimation" class="image">
            <div class="image-wrapper">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <!-- коли користувач підключився, але не має пулів -->
          <div v-if="selectPool && currentAddress" class="card card_empty">
            <div class="img"></div>
            <div class="h4">{{lang.get("SELECT_POOL")}}</div>
          </div>

          <div v-if="currentAddress && pools.length && showPools" class="wrapper_pool">
            <div
              v-for="(pool, index) in pools"
              :key="pool.poolVersion"
              class="card card_pool"
            >
              <div>
                <div class="h2">{{lang.get("POOL")}} {{ index + 1 }}</div>
                <div class="token">
                  <div class="token_img"><div :class="pool.name"></div></div>
                  <div class="token_title">{{ tokenTicker(pool.name) }}</div>
                </div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("POOL_STRENGTH")}}</div>
                    <div class="value-description">
                      {{
                        poolStrength(pool)
                          ? poolStrength(pool)
                          : 0.0
                      }}%
                    </div>
                  </div>
                  <div class="progress">
                    <div :style="{ width: poolStrength(pool) + '%' }"></div>
                  </div>
                </div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description title-with-addinfo">
                     <span class="text-left"> {{lang.get("EXPECTED_REWARD")}}</span>
                      <i v-if="poolStrength(pool) < 100" class="i-inform">
                        <div class="block-hover-descr">
                          <div class="hover-descr-title">
                            {{lang.get("EXPECTED_RETURN")}}
                            <p v-if="pool.referrerStatus" style="color:#5FDA9A;"> ({{lang.get("REFERRER_REWARD")}} +5%)</p>
                          </div>
                          <div
                            v-for="farmToken in pool.farmTokensList"
                            :key="farmToken.address"
                            class="hover-table"
                          >
                            <div class="hover-tr">
                              <div class="hover-td">{{ tokenTicker(farmToken.name) }}</div>
                              <div class="hover-td">
                                {{
                                  farmToken.possibleReward
                                    ? farmToken.possibleReward.toFixed(4)
                                    : "0.0000"
                                }}
                              </div>
                            </div>
                          </div>
                        </div></i
                      >
                    </div>
                  </div>
                  <div class="pool-reward">
                    <div
                      v-for="farmToken in getFarmTokens"
                      :key="farmToken.address"
                      class="token_img"
                    >
                      <div :class="farmToken.name"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="h2">APY</div>
                <div class="h1">120 - 170%</div>
                <div class="pool-block">
                  <div class="description">
                    <div class="title-description">{{lang.get("MAX_STAKE_LIMIT")}}</div>
                    <div class="value-description">
                      {{ pool.totalMaxStake.toLocaleString() }}
                    </div>
                  </div>
                  <div class="description">
                    <div class="title-description">{{lang.get("LOCK_IN")}}</div>
                    <div class="value-description">{{lang.get("OPEN")}}</div>
                  </div>
                  <div class="description">
                    <div class="title-description">{{lang.get("NETWORK")}}</div>
                    <div class="value-description">{{lang.get("ETHEREUM")}}</div>
                  </div>
                </div>
                <div v-if="poolStrength(pool) >= 100" class="container-button">
                  <button
                    class="button button_pull-full"
                    data-cy="button_pull-full"
                  >
                    <i class="i-check-full"></i>
                    {{lang.get("POOL_FULL")}}
                  </button>
                </div>
                <div
                  v-else-if="poolStrength(pool) < 100 && (tokenBalance < Number(stakeAmount) || tokenBalance < 1)"
                  class="container-button"
                >
                  <button
                    disabled
                    class="button button_stake-now"
                    data-cy="button_stake-now"
                  >
                    {{lang.get("STAKE_NOW")}}
                  </button>
                  <a :href="`https://app.uniswap.org/#/swap?inputCurrency=${pool.address}&use=V2`" target="_blank">
                  <button
                    class="button button_buy-matic"
                    data-cy="button_buy-matic"
                  >
                    <i class="i-label" style="color:inherit; mergin-right:8px; font-size:18px"></i>
                    {{lang.get("BUY")}} {{ tokenTicker(pool.name) }}
                  </button>
                  </a>
                </div>
                <div
                  v-else-if="poolStrength(pool) < 100 && tokenBalance >= Number(stakeAmount)"
                  class="container-button"
                >
                  <button
                    @click="stake(pool)"
                    class="button button_stake-now"
                    data-cy="button_stake-now-2"
                  >
                    {{lang.get("STAKE_NOW")}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ethers } from "ethers";
import MultiLang from "@/core/multilang";
import TokenSearch from "../../../components/modalWindows/TokenSearch.vue";
import { tokenAbi } from "../../../abi/tokenAbi.js";
import { mapState } from "vuex";
import TransactionStatus from "../../../components/modalWindows/TransactionStatus.vue";
import NewStake from "../../../components/modalWindows/NewStake.vue";
import CabinetNavigation from "@/components/CabinetNavigation";
import Loader from "../../../components/Loader.vue";
import Config from "../../../../Config.json";


export default {
  components: {
    TokenSearch,
    TransactionStatus,
    NewStake,
    CabinetNavigation,
    Loader,
  },
  name: 'Pool',
  data: function () {
    return {
      lang: new MultiLang(this),

      tokenAddress: "",
      tokenContract: null,
      selectedToken: "",
      tokenBalance: 0,
      tokenDecimals: 0,
      stakeAmount: "",
      lowBalance: false,
      incorrectAmount: false,
      showAnimation: true,
      selectPool: false,
      showPoolLimitExceedError: false,
      minStakeAmount: false,

      showTokensList: false,
      showStakeWindow: false,
      showPendingStake: false,
      showPendingApprove: false,
      showSuccessStake: false,
      showSuccessApprove: false,
      showLoader: false,
      showError: false,
      showPools: false,
      showPoolLimitReached: false,
      showInputError: false,

      pools: [],
      poolData: null,
      farmsTokens: [],
      poolsTotalStakeData: {},
      validReferrer: false
    };
  },
  mounted() {
    this.lang.init();
    let _this = this;

    const number = document.getElementById('number');

    // Listen for input event on numInput.
    number.onkeydown = function(e) {
        if(!((e.keyCode > 95 && e.keyCode < 106)
          || (e.keyCode > 47 && e.keyCode < 58) 
          || e.keyCode == 8
          || e.keyCode == 37 || e.keyCode == 39
          || e.keyCode == 188 || e.keyCode == 190
          )) {
            return false;
        }
    }
    setTimeout(async function updateComponentData() {
      try {
        await _this.getUserBalance()

        setTimeout(updateComponentData, 10000)
      } catch (error) {
        setTimeout(updateComponentData, 300)
      }
    })
  },

  computed: {
    ...mapState(["currentAddress", "stakeTokens", "tokensTotalStake"]),
    tokenImg() {
      //TODO check the address and set the proper class with icon
    },
    getFarmTokens() {
      if (this.stakeTokens && this.selectedToken) {
        let stakeTokenData = this.stakeTokens.filter(
          (token) => token.name === this.selectedToken
        );


        return stakeTokenData[0].farmTokensList;
      }
    },
    maxStake() {
      if(this.poolData) {
        return this.poolData.totalMaxStake - this.totalPoolStaking
      }
    }
  },

  methods: {

    async setupToken(token) {
      this.poolData = null;
      this.stakeAmount = "";
      this.lowBalance = false
      this.incorrectAmount = false
      this.showPoolLimitExceedError = false
      this.minStakeAmount = false
      this.showPools = false
      if(this.tokensTotalStake.V1 == undefined){
            this.$root.core.getSiteData()
      }
      const tokenAddress = token.target.attributes.value.value;
      this.selectedToken = token.target.attributes.name.value;
      if (this.pools.length != 0) {
        if (this.pools[0].address != tokenAddress) {
          this.pools = [];
        }
      }

      try {
        // const totalStake = await _this.$root.core.getTotalStaking(token)
        // _this.$store.commit('setTotalStake', totalStake)
        this.tokenContract = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          this.$root.core.providerAddress
        ).connect(this.$root.core.signer);

        let result = await this.tokenContract.balanceOf(this.currentAddress);

        this.tokenBalance = this.$root.core.withoutRound(
          ethers.utils.formatUnits(result._hex, "ether")
        );
        this.tokenAddress = tokenAddress;
        this.pools = this.stakeTokens.filter(
            (pool) => pool.address.toLowerCase() === this.tokenAddress.toLowerCase()
          );
        
      } catch (error) {
        console.log(error);
      }
    },

    redirectToStake(){
      setTimeout(()=> this.$router.push({name: 'stake'}), 2000)
    },
 
    async getUserBalance(){
      let result = await this.tokenContract.balanceOf(this.currentAddress);
      this.tokenBalance = this.$root.core.withoutRound(
          ethers.utils.formatUnits(result._hex, "ether")
        );
    },

    async stake(poolData) {
      this.poolData = poolData
      if (
        Number(this.stakeAmount) > Number(this.tokenBalance) && Number(this.stakeAmount) > 0
      ) {
        this.lowBalance = true;
      } else if (
        Number(this.stakeAmount) == 0){
        this.incorrectAmount = true
      }else {
        if(this.stakeAmount > this.poolData.totalMaxStake - this.totalPoolStaking){
          this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
        }else if (this.stakeAmount < this.poolData.userMinStake){
          return
        }
        this.showStakeWindow = true;
      }
    },

   async  setMaxValue() {

      if(this.poolData && this.tokenBalance >  this.poolData.totalMaxStake - this.totalPoolStaking){
        this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
      }else {
        this.stakeAmount = this.tokenBalance;
      }
      
      await this.getTokensRates()
    },

    async getTokensRates() {
      try {
          if (this.tokenAddress) {
        let V1TotalStake = this.tokensTotalStake.V1[this.tokenAddress.toLowerCase()]
        // let V2TotalStake = this.tokensTotalStake.V2[this.tokenAddress.toLowerCase()]
        this.poolsTotalStakeData = {
          V1TotalStake,
          // V2TotalStake
        }
        
          const pools = this.stakeTokens.filter(
            (pool) => pool.address.toLowerCase() === this.tokenAddress.toLowerCase()
          );


          for(let i = 0; i < pools.length; i++) {
            if(pools[i].referrerStatus == undefined){
              const referrerAddress = localStorage.getItem('ref') || ""

              if(referrerAddress.length == 42 && referrerAddress !== this.currentAddress){
                const res = await this.$root.core.checkReferrer(referrerAddress, pools[i].poolVersion);
                pools[i]['referrerStatus'] = res
                
              }
              pools[i]['referrerStatus'] = false
            }
          }


          pools.forEach((poolData, idx) => {
              for (let i = 0; i < poolData.farmTokensList.length; i++) {

                const userHourlyReard = (Number(this.stakeAmount) * poolData.farmTokensList[i].hourlyReward) / (this.poolsTotalStakeData[`V${idx+1}TotalStake`] + Number(this.stakeAmount))


              const possibleReward =
                Number(userHourlyReard * 24) *
                (Config.STAKE_DAYS - poolData.farmTokensList[i].interval);

              const fivePercFee = possibleReward * 5 / 100

              
              if(poolData.referrerStatus){
                poolData.farmTokensList[i].possibleReward = Number(possibleReward) - fivePercFee;
              }else {
                poolData.farmTokensList[i].possibleReward = Number(possibleReward) - (fivePercFee * 2);
              }
              
              } 
              poolData.poolTotalStake = this.poolsTotalStakeData[`V${idx+1}TotalStake`];
          });

          //reseting the array so that data has changed
          
          this.pools = pools;

      }
      } catch (error) {
        console.log(error);
      }

          
    },

    async findPools() {
      if(!this.selectedToken && !this.stakeAmount) {
        this.selectPool = true;
        this.showAnimation = false
        return
      }else if(this.selectedToken && !this.stakeAmount){
        this.showInputError = true
        return
      }
      this.showAnimation = false;

      if (this.selectedToken && this.stakeAmount) {
        if (
          Number(this.stakeAmount) > Number(this.tokenBalance) ||
          Number(this.tokenBalance) == 0
        ) {
          this.lowBalance = true;
          //  this.pools = Config.stakeTokens.filter(
          // (pool) => pool.name === this.selectedToken);
          
        }
        // if(Number(this.stakeAmount) > this.poolData.totalMaxStake - this.totalPoolStaking){
        //   this.stakeAmount = this.poolData.totalMaxStake - this.totalPoolStaking;
        //   this.showPoolLimitExceedError = true
        // }
        // if(Number(this.stakeAmount) < this.poolData.userMinStake){
        //   this.minStakeAmount = true;
        // }

        // this.pools = Config.stakeTokens.filter(
        //   (pool) => pool.name === this.selectedToken
        // );
        
         this.selectPool = false 
        //  this.getTokensRates()
         this.showPools = true
      }
    },
    checkBalance() {
      this.incorrectAmount = false
      this.showPoolLimitExceedError = false
      this.minStakeAmount = false
      this.showInputError = false


      if(Number(this.stakeAmount) > Number(this.tokenBalance)){
        this.lowBalance = true;
      }else {
        this.lowBalance = false
      }

      if(this.poolData && Number(this.stakeAmount) > (this.poolData.totalMaxStake - this.totalPoolStaking)){
        this.showPoolLimitExceedError = true
      }

      if(this.poolData && Number(this.stakeAmount) < this.poolData.userMinStake){
        this.minStakeAmount = true
      }
    },
    poolStrength(pool) {
     const res = (pool.poolTotalStake / pool.totalMaxStake) * 100 > 100
        ? 100
        : (pool.poolTotalStake / pool.totalMaxStake) * 100;
        return this.$root.core.withoutRound(res)
    },
    tokenTicker(tokenName) {
      return Config.tokenAliases[tokenName];
    }
}
};
</script>